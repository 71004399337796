<template>
  <div class="usercenter">
    <!-- 没有登入信息 -->
    <div class="noLogin" v-if="!userInfo && !wishInfo">
      <!-- <span class="iconfont icon-fanhui back" @click="goback"></span> -->
      <img src="../../assets/mescroll/mescroll-empty.png" alt />
      <!-- <div>
        <van-radio-group v-model="islogin" direction="horizontal">
          <van-radio name="1">会员登入</van-radio>
          <van-radio name="2">爱心企业登入</van-radio>
        </van-radio-group>
      </div>-->
      <p>
        您还没有登入，请先
        <span style="color: #1377e2" @click="toLogin">登入</span>
      </p>
    </div>
    <!-- 登入的用户信息 -->
    <div class="alreadyLogin titlesTop" v-if="userInfo && !wishInfo">
      <headTitle title="用户信息" style="background: #fff" />
      <div class="itemList">
        <span>头像</span>
        <img
          class="itm_img"
          :src="
            userInfo.avatar ? userInfo.avatar : require('../../assets/images/yonghu.png')
          "
          @click="onFileClick"
          alt
        />
      </div>
      <div @click="openNickNameEdit" class="itemList">
        <span>昵称</span>
        <span>{{ userInfo.nickname }}</span>
      </div>
      <div class="itemList" @click="openRealName">
        <span>姓名</span>
        <span>{{ userInfo.realName }}</span>
      </div>
      <div class="itemList">
        <span>手机号</span>
        <span>{{ userInfo.phone }}</span>
      </div>
      <div class="itemList" @click="openRealName">
        <span>身份证号</span>
        <span>{{ userInfo.idNumber }}</span>
      </div>
      <div class="itemList" @click="showSex = true">
        <span>性别</span>
        <span>{{ userInfo.sex | changeSex }}</span>
      </div>
      <div class="itemList" @click="showSpecial = true">
        <span style="display: inline-block; width: 120px">我的特长</span>
        <div class="itm_spe">
          <div v-for="item in userInfo.specialList" :key="item.id">{{ item.specialName }}</div>
        </div>
      </div>
      <!-- <div class="itemList" @click="isMywish">
        <span>我的心愿</span>
      </div>-->
      <div class="btncon">
        <van-button
          type="danger"
          round
          size="small"
          class="loginOut"
          @click="loginOut('huiyuan')"
        >退出登入</van-button>
      </div>
    </div>
    <!-- 登陆的爱心企业信息 -->
    <div class="alreadyLogin titlesTop" v-if="wishInfo && !userInfo">
      <headTitle title="企业信息" style="background: #fff" />
      <div class="itemList">
        <span>名称</span>
        <span>{{ wishInfo.name }}</span>
      </div>
      <div class="itemList">
        <span>联系人</span>
        <span>{{ wishInfo.contactPeople }}</span>
      </div>
      <div class="itemList">
        <span>手机号</span>
        <span>{{ wishInfo.phone }}</span>
      </div>
      <div class="btncon">
        <van-button type="danger" round size="small" class="loginOut" @click="loginOut('qiye')">退出登录</van-button>
      </div>
    </div>

    <!-- 特长弹窗 -->
    <van-popup
      @open="querySpecial"
      v-model="showSpecial"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div class="spe_hea">
        <span @click="showSpecial = false">取消</span>
        <span>选择特长</span>
        <span @click="selectSpecial">确认</span>
      </div>
      <div class="spe_rio">
        <van-checkbox-group v-model="result" direction="horizontal">
          <van-checkbox
            class="spe_itm"
            v-for="item in specialList"
            :key="item.id"
            :name="item.name"
            style="font-size:16px"
          >{{ item.name }}</van-checkbox>
        </van-checkbox-group>
      </div>
    </van-popup>

    <!-- 昵称修改弹窗 -->
    <van-dialog v-model="nickNameShow" title="修改昵称" show-cancel-button @confirm="editName">
      <van-field v-model="newNickName" clearable placeholder="请输入新昵称" input-align="center" />
    </van-dialog>

    <!-- 姓名身份证输入弹窗 -->
    <van-popup v-model="realNameShow">
      <div class="ren_pop">
        <van-field v-model="realName" clearable label="姓名" placeholder="请输入姓名" input-align="right" />
        <van-field
          v-model="idNumber"
          label="身份证号"
          clearable
          placeholder="请输入身份证号"
          input-align="right"
        />
        <div class="ren_btn">
          <div @click="realNameShow = false">取消</div>
          <div @click="editRealName">确认</div>
        </div>
      </div>
    </van-popup>

    <!-- 性别弹窗 -->
    <van-action-sheet
      v-model="showSex"
      :round="false"
      :actions="sexActions"
      cancel-text="取消"
      close-on-click-action
      @select="onSexSelect"
    />

    <!-- 头像上传框 -->
    <div class="hea_upa">
      <van-uploader
        v-show="false"
        v-model="fileList"
        :max-count="1"
        :before-read="beforeRead"
        :after-read="afterRead"
        id="cameraFile"
        class="upa_itm"
      />
    </div>
  </div>
</template>

<script>
import authApi from "@/api/auth";
import InteractionApi from "@/api/interaction";
import { Toast } from "vant";

export default {
  data() {
    return {
      userInfo: null,
      wishInfo: null,
      nickNameShow: false, // 昵称修改弹框
      nickname: "",
      newNickName: "", // 用户输入的新昵称
      sex: 2,
      avatar: "", // 头像
      islogin: "2", // 选择登入哪里
      dataSetinterval: null, // 信息定时器
      showSpecial: false, // 展示特长
      result: [],
      specialList: [],
      updateSpecial: [],
      showSex: false,
      sexActions: [
        { name: "女", value: 0 },
        { name: "男", value: 1 }
      ],
      fileList: [],
      realNameShow: false,
      realName: "", // 姓名
      idNumber: "", // 身份证号码
      personMSG: null,
    };
  },
  mounted() {
    if (
      window.localStorage.enterpriseInfo &&
      window.localStorage.enterpriseInfo != "undefined"
    ) {
      // 爱心企业登入
      this.wishInfo = JSON.parse(window.localStorage.enterpriseInfo);
      this.userInfo = null;
    } else if (
      window.localStorage.personalInformation &&
      window.localStorage.personalInformation != "undefined"
    ) {
      // 会员登入
      this.wishInfo = null;
      this.userInfo = JSON.parse(window.localStorage.personalInformation);
      this.newNickName = this.userInfo.nickname;
      this.sex = this.userInfo.sex;
      this.avatar = this.userInfo.avatar;
      this.realName = this.userInfo.realName;
      this.idNumber = this.userInfo.idNumber;
      this.transformSpecial();
    } else {
      // 都没登入
      this.wishInfo = null;
      this.userInfo = null;
    }
  },
  filters: {
    changeSex(value) {
      if (value === 0) {
        return "女";
      } else if (value === 1) {
        return "男";
      } else {
        return "男";
      }
    },
  },
  methods: {
    goback() {
      this.$router.go(-1); //返回上一级
    },
    // 调到登入
    toLogin() {
      this.$router.push("/login");
    },
    //退出登录
    loginOut(value) {
      window.localStorage.personalInformation = "";
      window.localStorage.userinfo = "";
      window.localStorage.enterpriseInfo = "";
      window.localStorage.oldWishToken = "";
      this.wishInfo = null;
      this.userInfo = null;
      this.$router.go(-1); //返回上一级
      setInterval(() => {
        window.location.reload(); // 刷新页面
      }, 100);
    },
    isMywish() {
      // 我的心愿
      var link = window.location.href.split("#/")[0] + "#/mywish"; //登入页
      window.location.href = link;
    },

    // 头像上传检验不超过8M
    beforeRead(file) {
      console.log('157854855', file);
      const imgMaxSize = 8 * 1024 * 1024;
      var arr = [];
      arr = file.type.split("/");
      const fileType = arr[0];
      // 获取后缀名
      let index = file.name.lastIndexOf(".");
      file.fileSuffix = file.name.substr(index + 1);
      if (fileType !== "image") {
        Toast("请上传图片!");
        return false;
      }

      if (file.size > imgMaxSize) {
        Toast("单张图片大小不能超过5M");
        return false;
      }
      return true;
    },

    afterRead(file) {
      console.log(file);
      file.fileName = file.file.name;
      var formData = new FormData();
      formData.append("file", file.file);
      console.log('我拿到了吗', formData.get("file"));

      const params = {
        deviceNumber: this.userInfo.phone, // 设备号
        mediaSuffix: file.file.fileSuffix, // 文件后缀
      };
      Toast.loading({
        message: "头像上传中...",
        forbidClick: true,
        duration: 0,
      });
      InteractionApi.upLoadImg(formData, params)
        .then((res) => {
          // console.log(res);
          if (res.success) {
            this.avatar = res.data;
            this.fileList = [];
            this.updateUserInfo();

          } else {
            this.fileList = [];
            Toast("头像上传失败");
          }
        })
        .catch((err) => {
          Toast("头像上传失败");
          // alert(JSON.stringify(err.message));
          this.fileList = [];
          console.log(err);
        });
    },

    // 查询所有特长
    querySpecial() {
      // console.log(this.result);
      authApi
        .getAllSpecial()
        .then((res) => {
          this.specialList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 格式特长数据
    transformSpecial() {
      if (this.userInfo.specialList) {
        // console.log(this.userInfo.specialList);
        let sList = this.userInfo.specialList;
        for (let i in sList) {
          this.result.push(sList[i].specialName);
          let obj = {
            name: sList[i].specialName,
            id: sList[i].id,
          };
          this.updateSpecial.push(obj);
        }
      }
    },

    // 选择特长，更新用户信息
    selectSpecial() {
      if (!this.result.length) {
        Toast("请最少选择一项特长");
        return;
      }
      this.updateSpecial = [];
      // console.log(this.result);
      let res = this.result;
      let sst = this.specialList;
      for (let i in res) {
        for (let k in sst) {
          if (res[i] === sst[k].name) {
            let obj = {
              name: sst[k].name,
              id: sst[k].id,
            };
            this.updateSpecial.push(obj);
          }
        }
      }
      // console.log(this.updateSpecial);
      this.showSpecial = false;
      this.updateUserInfo();
    },

    // 打开昵称框
    openNickNameEdit() {
      this.newNickName = this.userInfo.nickname;
      this.nickNameShow = true;
    },

    // 修改昵称
    editName() {
      if (this.newNickName.length === 0) {
        Toast("请输入昵称");
      }
      if (this.newNickName.length > 15) {
        Toast("昵称不能超过十五位");
        return;
      }
      this.nickNameShow = false;
      this.updateUserInfo();
    },

    // 打开姓名弹窗
    openRealName() {
      if (!this.userInfo.realName) {
        this.realNameShow = true;
      }
    },
    // 输入姓名和身份证号
    editRealName() {
      if (!this.realName) {
        Toast("请输入姓名");
        return;
      }
      if (!this.idNumber) {
        Toast("请输入身份证号");
        return;
      } else {
        var pattern = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/;
        if (!pattern.test(this.idNumber)) {
          Toast("请输入合法的18位身份证号");
          return;
        }
      }
      this.realNameShow = false;
      this.updateUserInfo();
    },

    // 选择性别
    onSexSelect(val) {
      // console.log(val);
      this.showSex = false;
      this.sex = val.value;
      this.updateUserInfo();
    },

    // 选择照片
    onFileClick() {
      // console.log(this.fileList);
      const doc = document.getElementById("cameraFile");
      return doc.click();
    },

    // 更新用户信息
    updateUserInfo() {
      // const special = [];
      // const obj = {};
      const data = {
        avatar: this.avatar,
        id: this.userInfo.id,
        idNumber: this.idNumber,
        nickname: this.newNickName,
        realName: this.realName,
        sex: this.sex,
        specialList: this.updateSpecial,
      };
      // console.log(data);
      authApi
        .updateInfo(data)
        .then((res) => {
          // console.log(res);
          // Toast(res.message);
          if (res.code === 20049) {
            Toast(res.message);
          } else {
            this.MemberInfoData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 查询会员信息
    MemberInfoData() {
      authApi
        .memberInfomatin()
        .then((res) => {
          if (res.success) {
            Toast.clear();
            this.userInfo = res.data;
            const data = JSON.stringify(res.data);
            window.localStorage.personalInformation = data;
            // window.location.reload(); // 刷新页面
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.usercenter .van-uploader__upload {
  width: 5px;
  height: 5px;
}
</style>
<style lang="less" scoped>
.alreadyLogin {
  width: 100%;
  height: 100%;
}
.noLogin {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  p {
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
}
.usercenter {
  .back {
    font-size: 20px;
    position: absolute;
    left: 10px;
    top: 40px;
    z-index: 999;
  }
  .btncon {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .loginOut {
      padding: 0 15px;
      font-size: 15px;
      margin: auto;
    }
  }

  .tit {
    text-align: center;
    height: 30px;
    line-height: 30px;
    padding: 5px 0;
    margin-top: 15px;
  }
  .itemList {
    margin: 10px 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 16px;
    box-sizing: border-box;
    .itm_img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .itm_spe {
      overflow-x: scroll;
      display: flex;
      flex-wrap: nowrap;
      div {
        white-space: nowrap;
        padding: 0 3px;
      }
    }
  }
  background: #fafafa;
  .spe_hea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
    border-bottom: 1px solid #ececec;
    font-size: 14px;
    span {
      &:nth-child(2) {
        font-size: 16px;
      }
      &:nth-child(3) {
        color: #1989fa;
      }
    }
  }
  .spe_rio {
    padding: 10px;
    .spe_itm {
      padding: 4px 0;
    }
  }
  .hea_upa {
    position: absolute;
    top: 30px;
    right: -89px;
    z-index: -10;
  }
  .ren_pop {
    width: 90vw;
    .ren_btn {
      padding: 15px 20px;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      div {
        &:nth-child(2) {
          color: #ee0a24;
        }
      }
    }
  }
}
</style>
